<template>
    <div class="base-check-box">
        <label
            class="checkbox-wrapper"
        >
            {{ label }}
            <input
                v-bind="$attrs"
                type="checkbox"
                :id="`base-check-box__input-${id}`"
                :checked="isChecked"
            />
            <span
                class="base-check-box__check-mark"
                :class="[
                    { 'base-check-box__check-mark--checked': isChecked },
                    `base-check-box__check-mark--${size}`
                ]"
            >
                <img
                    v-if="isChecked"
                    src="../../assets/icons/icon-check-v2.svg"
                >
            </span>
        </label>
    </div>
</template>

<script>
    export default {
        props: {
            label: {
                type: String,
                default: ''
            },

            id: {
                type: String,
                default: 'id'
            },

            isChecked: {
                type: Boolean,
                default: false
            },

            size: {
                type: String,
                default: 'small'
            }
        },
    }
</script>

<style src="./BaseCheckBox.scss" lang="scss"></style>